import React, { useState } from 'react';
import './Main.css';

export default function Frame4() {
    const [activeCard, setActiveCard] = useState(null);  // State to track the currently active card

    const showCard = (cardId, event) => {
        // Hide all other cards by resetting their z-index and opacity
        const allCards = document.querySelectorAll('.f4b3cards');
        allCards.forEach((card) => {
            card.classList.remove('show');  // Remove the 'show' class to hide all other cards
            card.style.zIndex = 1;  // Set z-index to a lower value for all cards
        });
    
        // Get the clicked card and circle elements
        const cardElement = document.getElementById(`card${cardId}`);
        const circleElement = event.target;
    
        // Get the left position of the clicked circle
        const circleRect = circleElement.getBoundingClientRect();
        const circleLeft = circleRect.left;
    
        // Dynamically position the card above the clicked circle and center it
        cardElement.style.left = `${circleLeft - 200}px`;  // Adjust the offset to center the card
    
        // Show the clicked card with higher z-index and opacity
        cardElement.style.zIndex = 2;  // Set the z-index higher for the active card
        cardElement.classList.add('show');  // Add the 'show' class to make it visible
    
        // Update the active card state
        setActiveCard(cardId);
    };
    
    

    return (
        <>
            <div id="frame4">
                <div id="f4b1">
                    <div id="f4b1b1">
                        <div className="boxheads1">See</div>
                        <div className='boxheads2'>What Sets Us Apart</div>
                        <div className="boxheadsdesc">Discover What Makes Our Resort Unique</div>
                    </div>
                    <div id="f4b1b2">
                        <div className="f4b1b2cards" id="total"><div className="cardhead1">Total</div><div className="cardhead2">50 Camps</div></div>
                        <div className="f4b1b2cards" id='f4b1b2cardparts'>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total Area</div><div className="cardhead2">4000 Sqft</div></div>
                            <div className="f4b1b2cardparts"><div className="cardhead1">Total</div><div className="cardhead2">3000 Visitors</div></div>
                        </div>
                        <div className="f4b1b2cards" id="city2"><div className="cardhead1">Drive from city</div><div className="cardhead2">44 Km</div></div>
                    </div>
                </div>
                <div id="f4b2">
                    <div id="f4b2b1">
                        <div className="boxheads1">Explore</div>
                        <div className='boxheads2'>Camp Itinerary</div>
                    </div>
                    <div id="f4b2b2">
                        <div className="boxheadsdesc">Immerse Yourself in an Unforgettable Journey with Our Carefully Curated Itinerary, Packed with Exciting Activities, Scenic Exploration, and Memorable Experiences Awaiting You Each Day.</div>
                    </div>
                </div>
                <div id="f4b3">
                    <div id="forcards">
                        <div className={`f4b3cards ${activeCard === 1 ? 'show' : ''}`} id='card1'>
                            <div className="f4b3cardheads">Swiss Tent Accommodation</div>
                            <div className="f4b3carddesc">Experience a luxurious stay in our star-rated Swiss tents nestled in the heart of the Thar Desert, complete with all modern amenities.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 2 ? 'show' : ''}`} id='card2'>
                            <div className="f4b3cardheads">Gaala Dinner</div>
                            <div className="f4b3carddesc">Savor authentic Rajasthani cuisine at our desert buffet, offering a true taste of tradition.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 3 ? 'show' : ''}`} id='card3'>
                            <div className="f4b3cardheads">Cultural Program & DJ Party</div>
                            <div className="f4b3carddesc">Ignite your evening with live Rajasthani folk music and dances, followed by an electrifying DJ party.</div>
                        </div>
                        <div className={`f4b3cards ${activeCard === 4 ? 'show' : ''}`} id='card4'>
                            <div className="f4b3cardheads">Camel Safari</div>
                            <div className="f4b3carddesc">Delight in thrilling activities around Jaisalmer city and embark on unforgettable desert safari tours at Sam Sand Dunes.</div>
                        </div>
                    </div>
                    <div id="forblocks">
                        <div className={`circles ${activeCard === 1 ? 'active-circle' : ''}`} id='circle1' onClick={(event) => showCard(1, event)}></div>
                        <div className={`circles ${activeCard === 2 ? 'active-circle' : ''}`} id='circle2' onClick={(event) => showCard(2, event)}></div>
                        <div className={`circles ${activeCard === 3 ? 'active-circle' : ''}`} id='circle3' onClick={(event) => showCard(3, event)}></div>
                        <div className={`circles ${activeCard === 4 ? 'active-circle' : ''}`} id='circle4' onClick={(event) => showCard(4, event)}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useState } from 'react';
import Slide1 from './Images/Slide1.JPG';
import Slide2 from './Images/Slide2.JPG';
import './Main.css';

export default function Frame2() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { src: Slide1, title: "Unique Cozy Retreat", desc:`Experience luxury in a desert tent nestled among the sand dunes, enveloped in a peaceful and serene ambiance. The tent boasts plush bedding, sophisticated decor, and traditional Rajasthani elements, offering a cozy and genuine desert retreat.`, className: "slide1" },
        { src: Slide2, title: "Sand Dune Expedition", desc:`Embark on an exciting adventure with a jeep and camel safari through the sand dunes, guided by an expert driver who skillfully maneuvers the challenging terrain. Enjoy breathtaking scenic views that provide a fresh and exhilarating perspective of the desert landscape.`, className: "slide2" }
    ];

    const handlenextClick = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handleprevClick = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <>
            <div id="frame2">
                <div id="f2b1">
                    <div id="f2b1b1">
                        <div className="boxheads1">Experience</div>
                        <div className='boxheads2'>{slides[currentSlide].title}</div>
                    </div>
                    <div className="boxheadsdesc">{slides[currentSlide].desc}</div>
                </div>
                <div id="f2b2">
                    <div id="f2b2sliderbtnprev" className="f2b2sliderbtn" onClick={handleprevClick}>&larr;</div>
                    <div id="f2b2slider">
                        <img src={slides[currentSlide].src} alt="" id="slide" className={slides[currentSlide].className} />
                    </div>
                    <div id="f2b2sliderbtnnext" className="f2b2sliderbtn" onClick={handlenextClick}>&rarr;</div>
                </div>
            </div>
        </>
    );
}

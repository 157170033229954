import React from 'react';
import Abframe1 from './Abframe1';
import Dbframe2 from './dbframe2';
import background from './Images/ds.jpg';

const About = () => {
    return (
        <div>
            <Abframe1 background={background} head1={"Explore"} head2={"Desert Safari & Activities"} desc={"The one-night camping experience in Jaisalmer provides a variety of activities to keep you engaged and entertained. Enjoy camel rides, jeep safaris, and quad biking to explore the stunning sand dunes. You’ll also have the chance to experience captivating cultural performances, featuring traditional Rajasthani folk music and dance. In the evening, stargazing under the clear desert sky is a popular and enchanting activity."}></Abframe1>
            <Dbframe2/>

        </div>
    );
};

export default About;
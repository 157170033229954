import './Main.css';
import React, { useState } from 'react';
import Slide1 from './Images/cottage.JPG';
import Slide2 from './Images/jeepsafari.JPG';
import Slide3 from './Images/camelsafari.JPG';
import Slide4 from './Images/quad.JPG';
import Slide5 from './Images/para.JPG';
import Slide6 from './Images/pvt.JPG';
import Slide7 from './Images/fire.JPG';
import Slide8 from './Images/program.JPG';
import Slide9 from './Images/city.JPG';


export default function Frame3() {
    const [slideIndex, setSlideIndex] = useState(0);
    const [hovered, setHovered] = useState(null); 

    const slidesData = [
        {
            head1: 'Desert Camps and Cottages',
            head2: 'Jeep Safari',
            head3: 'Camel Safari',
            slide1: Slide1,
            slide2: Slide2,
            slide3: Slide3
        },
        {
            head1: 'Quad Biking',
            head2: 'Paraselling & Paramotoring',
            head3: 'Private Dinner on Dunes',
            slide1: Slide4,
            slide2: Slide5,
            slide3: Slide6
        },
        {
            head1: 'Camp Fire',
            head2: 'Cultural Programs',
            head3: 'City Tour',
            slide1: Slide7,
            slide2: Slide8,
            slide3: Slide9
        }
    ];

    const handlenextClick = () => {
        setSlideIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
    }

    const handleprevClick = () => {
        setSlideIndex((prevIndex) => (prevIndex - 1 + slidesData.length) % slidesData.length);
    }

    const getSlideStyle = (slideNum) => {
        const slideStyles = [
            {width: "30vw", height:"100vh", transform:"translate(-0vw,-45vh)"}, // Style for Slide1
            {width: "30vw", height:"50vh", transform:"translate(-5vw,0vh)"}, // Style for Slide2
            {width: "35vw", height:"50vh", transform:"translate(-5vw,0vh)" }, // Style for Slide3
            {width: "35vw", transform:"translate(-5vw,-15vh)" }, // Style for Slide4
            {width: "35vw", transform:"translate(-10vw,0vh)"  }, // Style for Slide5
            {height: "50vh",transform:"translate(-10vw,0vh)" }, // Style for Slide6
            {width: "30vw", transform:"translate(0vw,-40vh)" }, // Style for Slide7
            {width: "33vw" }, // Style for Slide8
            {width: "30vw", transform:"translate(0vw,-15vh)" }  // Style for Slide9
        ];

        return slideStyles[slideNum];
    };

    return (
        <>
            <div id="frame3">
                <div id="f3b1">
                    <div id="f3b1b1">
                        <div className='boxheads1'>Discover</div>
                        <div className='boxheads2'>Desert Pearl Resort</div>
                        <div className="boxheadsdesc">Explore the Unique Experiences Awaiting You in the Heart of the Desert</div>
                        <div id="forbtnflex">
                            <div id="f2b2sliderbtnprev" className="f2b2sliderbtn2" onClick={handleprevClick}>&larr;</div>
                            <div id="f2b2sliderbtnnext" className="f2b2sliderbtn2" onClick={handlenextClick}>&rarr;</div>
                        </div>
                    </div>
                    <div id="f3b1b2">
                        <div className="f3b1b2img">
                            <img src={slidesData[slideIndex].slide1} alt="" style={getSlideStyle(slideIndex * 3)}/>
                            <div className="bottomtxt">{slidesData[slideIndex].head1}</div>
                        </div>
                        <div className="f3b1b2img">
                            <img src={slidesData[slideIndex].slide2} alt="" style={getSlideStyle(slideIndex * 3 + 1)}/>
                            <div className="bottomtxt">{slidesData[slideIndex].head2}</div>
                        </div>
                        <div className="f3b1b2img">
                            <img src={slidesData[slideIndex].slide3} alt="" style={getSlideStyle(slideIndex * 3 + 2)}/>
                            <div className="bottomtxt">{slidesData[slideIndex].head3}</div>
                        </div>
                    </div>
                </div>
                <div id="f3b2">
                    <div id="f3b2b1" className='boxheads2'>
                        About Us
                    </div>
                    <div id="f3b2b2">
                        <div className="boxheadsdesc">
                            Jaisalmer offers a wonderful opportunity to immerse yourself in the Thar Desert's nighttime beauty, surrounded by enchanting stars. Spend your time under a sky filled with dazzling stars—a sight often missed in the hustle of city life. Our desert camping experience in this vibrant Rajasthan city provides a safe and extraordinary escape. Enjoy a blissful stay with us and indulge in the unique luxury of our lavish tents, camps, and cottages for a truly unforgettable desert camping adventure.
                        </div>
                        <button id='expbtn'>Know More</button>
                    </div>
                </div>
            </div>
        </>
    );
}

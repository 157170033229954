import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import fb from './Images/fb.png';
import insta from './Images/insta.png';
import google from './Images/google.png';
import email from './Images/email.png';
import city from './Images/city.png';
import phone from './Images/phone.png';
import location from './Images/location.png';

import './Main.css';

export default function Login() {

    return (
        <>
            <div id="footer">
                <div id="f11">
                    <div id="f1">
                        <div className="rowf">
                            <Link to="" className="sociallinks"><img src={fb} alt="" className='sociallinksimg' /></Link>
                            <Link to="" className="sociallinks"><img src={insta} alt="" className='sociallinksimg' /></Link>
                            <Link to="" className="sociallinks"><img src={google} alt="" className='sociallinksimg' /></Link>
                            <Link to="" x className="sociallinks"><img src={email} alt="" className='sociallinksimg' /></Link>
                        </div>
                        <div className="rowf">
                            <img src={city} alt="" className='sociallinksimg' />
                            <div className="sstxt">
                                <div className="sstxt1">City</div>
                                <div className="sstxt2">Jaisalmer</div>
                            </div>
                        </div>
                        <div className="rowf">
                            <img src={phone} alt="" className='sociallinksimg' />
                            <div className="sstxt">
                                <div className="sstxt1">Phone Number</div>
                                <div className="sstxt2">+91 9214509366</div>
                            </div>
                        </div>
                        <div className="rowf">
                            <img src={location} alt="" className='sociallinksimg' />
                            <div className="sstxt">
                                <div className="sstxt1">Location</div>
                                <div className="sstxt2">Sam Sand Dunes, Jaisalmer</div>
                            </div>
                        </div>
                    </div>
                    <div id="f2">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.8774505706515!2d70.54076247522188!3d26.84384987668821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3947bc258f1f1b3b%3A0x9c690201e406e12b!2sDesert%20Pearl%20Camp%20%26%20Resort!5e0!3m2!1sen!2sin!4v1726810068972!5m2!1sen!2sin" id='iframe' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>
                <div id="f22">
                    <div className="f22txt">© Desert Pearl Camp Resort 2023. Developed By Zenith</div>
                    <div className="f22txt">Cancellation & Refund | Privacy Policy | Terms & Conditions</div>
                </div>
            </div>
        </>
    );
}
